.ArticleShare-module__itlijq__shareContainer {
  margin: var(--zbx-spacing-4) 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ArticleShare-module__itlijq__shareLink {
  margin: 0 var(--zbx-spacing-2);
  cursor: pointer;
  border: 2px solid var(--linkfarbe);
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  display: flex;
}

.ArticleShare-module__itlijq__snackbar {
  color: #fff;
  z-index: 1000;
  font-size: var(--font-size-base);
  background-color: #4caf50;
  border-radius: 5px;
  padding: 10px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.blog-module__dkkjTG__blog {
  margin-bottom: var(--zbx-spacing-2);
}

.blog-module__dkkjTG__blogRow {
  justify-content: center;
  width: 100%;
  display: flex;
}

.blog-module__dkkjTG__blogTitle {
  padding: 0 6.25rem;
}

.blog-module__dkkjTG__blogTitle h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.blog-module__dkkjTG__blogContent {
  padding: 0 var(--zbx-spacing-5) 0 6.25rem;
  flex: 0 0 50%;
}

.blog-module__dkkjTG__blogContent img {
  transition: all .2s linear;
}

.blog-module__dkkjTG__blogContent img:hover {
  transform: scale(1.01);
}

.blog-module__dkkjTG__blogContent h5 {
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
}

.blog-module__dkkjTG__blogSidebar {
  padding: var(--zbx-spacing-6) 7.5rem var(--zbx-spacing-6) var(--zbx-spacing-5);
}

.blog-module__dkkjTG__ctaButton {
  width: 100%;
  margin-top: var(--zbx-spacing-8);
}

.blog-module__dkkjTG__ctaButton:hover {
  -webkit-text-decoration: underline 4px solid var(--primary-2);
  text-decoration: underline 4px solid var(--primary-2);
  text-underline-position: under;
}

.blog-module__dkkjTG__blogCategories {
  border-bottom: 1px solid var(--secondary-2);
}

.blog-module__dkkjTG__blogCategories button {
  padding-left: var(--zbx-spacing-4);
  padding-right: var(--zbx-spacing-4);
  color: var(--primary-5);
}

.blog-module__dkkjTG__blogCategories h5 {
  margin-bottom: var(--zbx-spacing-5);
}

.blog-module__dkkjTG__blogPreview {
  margin-bottom: var(--zbx-spacing-8);
}

.blog-module__dkkjTG__blogContent p {
  font-size: var(--font-size-md);
}

.blog-module__dkkjTG__categoriesTitle {
  margin-bottom: var(--zbx-spacing-5);
}

.blog-module__dkkjTG__categoriesTitle h5 {
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  letter-spacing: normal;
  color: var(--primary-2);
  line-height: normal;
}

.blog-module__dkkjTG__categoriesPills {
  gap: var(--zbx-spacing-5) var(--zbx-spacing-5);
  margin-bottom: var(--zbx-spacing-6);
  flex-wrap: wrap;
  display: flex;
}

.blog-module__dkkjTG__categoriesPills div:last-child button {
  border: 1px solid var(--primary-5);
  background-color: #fff;
}

.blog-module__dkkjTG__categoriesPills div:last-child button:hover {
  background-color: var(--primary-2);
}

.blog-module__dkkjTG__blogArticles {
  margin-top: var(--zbx-spacing-8);
  margin-left: var(--zbx-spacing-3);
}

.blog-module__dkkjTG__blogArticles h5 {
  margin-bottom: var(--zbx-spacing-7);
  margin-top: 0;
}

.blog-module__dkkjTG__blogArticles p {
  margin-bottom: 3px;
}

.blog-module__dkkjTG__blogArticles div figure {
  margin: 0 var(--zbx-spacing-4) 0 0;
}

@media (max-width: 1024px) {
  .blog-module__dkkjTG__blogContent {
    flex: 0 0 46%;
  }
}

@media (max-width: 960px) {
  .blog-module__dkkjTG__blogRow {
    flex-direction: column;
  }

  .blog-module__dkkjTG__blogContent, .blog-module__dkkjTG__blogSidebar, .blog-module__dkkjTG__blogTitle {
    padding-left: var(--zbx-spacing-8);
    padding-right: var(--zbx-spacing-8);
    width: auto;
  }

  .blog-module__dkkjTG__blogSidebar {
    padding-top: 0;
  }
}

.BlogArticlePreview-module__hRfWba__blogArticlePreview {
  flex-direction: column;
  display: flex;
}

.BlogArticlePreview-module__hRfWba__imageLink {
  text-decoration: none;
}

.BlogArticlePreview-module__hRfWba__imageWrapper {
  aspect-ratio: 16 / 9;
  width: 100%;
  position: relative;
}

.BlogArticlePreview-module__hRfWba__image {
  object-fit: cover;
}

.BlogArticlePreview-module__hRfWba__category {
  margin-top: var(--zbx-spacing-4);
  font-family: var(--zbx-font-lato);
  font-weight: var(--font-weight-light);
}

.BlogArticlePreview-module__hRfWba__date {
  color: var(--primary-2);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-light);
}

.BlogArticlePreview-module__hRfWba__categoryName {
  text-transform: uppercase;
  color: var(--primary-2);
  font-size: var(--font-size-sm);
}

.BlogArticlePreview-module__hRfWba__separator {
  margin: 0 .5rem;
}

.BlogArticlePreview-module__hRfWba__titleLink {
  margin-top: .5rem;
  text-decoration: none;
}

.BlogArticlePreview-module__hRfWba__title {
  color: var(--primary-2);
  margin: 0;
}

.BlogArticlePreview-module__hRfWba__textLink {
  text-decoration: none;
}

.BlogArticlePreview-module__hRfWba__text {
  margin-top: var(--zbx-spacing-4);
  margin-bottom: 1rem;
}

.BlogArticlePreview-module__hRfWba__text a {
  color: var(--gold);
  font-weight: 700;
  text-decoration: none;
}

.BlogArticlePreview-module__hRfWba__text a:hover {
  text-decoration: underline;
}

.BlogArticlePreview-module__hRfWba__text p {
  margin: 0;
}

@media (min-width: 768px) {
  .BlogArticlePreview-module__hRfWba__text {
    margin-top: 1.25rem;
  }
}

.image-module___iEPfa__imageContainer {
  margin: 0;
  overflow: hidden;
}

.image-module___iEPfa__imageContainer a {
  z-index: 0;
}

.image-module___iEPfa__responsiveSizer {
  flex: 1 0 0;
  transition: padding-bottom .2s cubic-bezier(.25, .8, .5, 1);
}

.image-module___iEPfa__image figure {
  background-image: url("https://www.zebrabox.ch/sites/default/files/styles/subnav/public/2023-01/teaser-text-image-zebrabox-van_1.png?h=549d8674&itok=ml-hZffb");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.image-module___iEPfa__zoomableImg {
  transition: all .2s ease-in-out;
}

.image-module___iEPfa__zoomableImg:hover {
  transform: scale(1.1);
}

.image-module___iEPfa__altText {
  width: 100%;
  color: var(--primary-2);
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.image-module___iEPfa__imageSkeleton {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

.pillbutton-module__-aoQFW__container {
  justify-content: center;
  align-items: center;
  display: flex;
}

button.pillbutton-module__-aoQFW__pillButton {
  background-color: var(--secondary-3);
  opacity: 1;
  width: -moz-fit-content;
  width: fit-content;
  height: 42px;
  color: var(--primary-2);
  border-radius: 22px;
  padding: 10px;
  transition: all .2s;
  transition: all .2s cubic-bezier(.4, 0, .2, 1) !important;
}

button.pillbutton-module__-aoQFW__pillButton:hover {
  cursor: pointer;
  color: var(--primary-3);
  background-color: var(--primary-2);
  transition: all .2s;
}

button.pillbutton-module__-aoQFW__pillButton:active, button.pillbutton-module__-aoQFW__activePillButton {
  background-color: var(--primary-1);
  color: var(--primary-2);
}

button.pillbutton-module__-aoQFW__disabledPillButton {
  background-color: var(--secondary-3);
}

span.pillbutton-module__-aoQFW__pillSpan {
  font-family: var(--zbx-font-lato);
  letter-spacing: normal;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  margin-top: 3px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.33;
}

.hamburgersubmenu-module__kCOSxa__aboutUsGrid {
  align-items: flex-start;
}

.hamburgersubmenu-module__kCOSxa__aboutUsGrid > div {
  max-width: 85%;
}

.accordion-module__elN07q__accordionContainer, .accordion-module__elN07q__accordionMain {
  width: 100%;
}

.accordion-module__elN07q__accordionItem {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  transition: height .1s ease-in-out;
}

.accordion-module__elN07q__accordionItem:hover {
  cursor: pointer;
  transition: height .1s ease-in-out;
}

.accordion-module__elN07q__accordionTrigger {
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-normal);
  letter-spacing: normal;
  border: none;
  border-bottom: 1px solid var(--secondary-4);
  background: none;
  width: 100%;
  padding: 1rem 1.25rem;
  transition: all .1s ease-in-out;
}

.accordion-module__elN07q__accordionTrigger[data-state="open"] {
  background-color: var(--secondary-3);
  font-weight: var(--font-weight-bold);
  transition: all .1s ease-in-out;
}

.accordion-module__elN07q__accordionTrigger:hover {
  cursor: pointer;
  background-color: var(--secondary-3);
  transition: all .1s ease-in-out;
}

.accordion-module__elN07q__accordionContent {
  background-color: var(--secondary-3);
  width: 100%;
  overflow: hidden;
}

.accordion-module__elN07q__accordionContent > div {
  flex-direction: column;
  width: 100%;
  padding: 0;
  display: flex;
}

.accordion-module__elN07q__accordionContent[data-state="open"] {
  animation: .3s cubic-bezier(.87, 0, .13, 1) accordion-module__elN07q__slideDown;
}

.accordion-module__elN07q__accordionContent[data-state="closed"] {
  animation: .3s cubic-bezier(.87, 0, .13, 1) accordion-module__elN07q__slideUp;
}

.accordion-module__elN07q__accordionContent .accordion-module__elN07q__accordionTrigger {
  background-color: var(--secondary-3);
  border-bottom: 1px solid var(--secondary-4);
}

.accordion-module__elN07q__accordionContent .accordion-module__elN07q__accordionTrigger:hover {
  background-color: var(--secondary-2);
}

.accordion-module__elN07q__finalNestedContainer {
  justify-content: center;
  width: 100%;
  display: flex;
}

.accordion-module__elN07q__finalNestedItem, .accordion-module__elN07q__finalNestedContainer a {
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-light);
  background: var(--secondary-3);
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1.25rem 0;
  display: flex;
}

.accordion-module__elN07q__finalNestedItem:hover {
  background: var(--secondary-2);
}

@keyframes accordion-module__elN07q__slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes accordion-module__elN07q__slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}

.accordionsubmenu-module__NT0A_a__accordionSubmenuContainer {
  width: 100%;
  max-height: 460px;
  z-index: var(--z-index-above-nav);
  top: 116px;
  overflow: scroll;
}

.accordionsubmenu-module__NT0A_a__accordionSubmenuBtn {
  border-bottom: 1px solid var(--secondary-4);
  padding: 16px 32px;
}

.accordionsubmenu-module__NT0A_a__accordionSubmenuBtn a {
  vertical-align: middle;
}

.accordionsubmenu-module__NT0A_a__accordionSubmenuLangSwitch {
  border-bottom: 1px solid var(--secondary-4);
}

.accordionsubmenu-module__NT0A_a__accordionSubmenuCountrySwitch {
  all: unset;
  justify-content: center;
  padding: 1.5rem 2rem;
  display: flex;
}

.accordionsubmenu-module__NT0A_a__accordionSubmenuCountrySwitch button {
  background: none;
  border: none;
}

.accordionsubmenu-module__NT0A_a__accordionSubmenuCountrySwitch button div {
  opacity: 1;
}

.toolbar-module__yM1JTG__ToolbarContainer {
  width: 100%;
  height: inherit;
  max-width: 1440px;
  z-index: var(--z-index-nav-second-level);
  will-change: height, top;
  grid-template-columns: 10% 85%;
  align-items: center;
  margin: auto;
  display: grid;
  top: 85px;
}

.toolbar-module__yM1JTG__ToolbarScrollarea {
  --scrollbar-size: 10px;
  border-radius: 4px;
  max-width: 65%;
  overflow: hidden;
}

.toolbar-module__yM1JTG__ToolbarElements {
  white-space: nowrap;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}

.toolbar-module__yM1JTG__ToolbarContainerScrolled {
  max-width: 1440px;
  z-index: var(--z-index-nav);
  will-change: height, top;
  align-items: center;
  margin: auto;
  transition: height .2s ease-in-out, top .2s ease-in-out;
  position: fixed;
  top: 80px;
}

.toolbar-module__yM1JTG__ToolbarContainerScrolled .toolbar-module__yM1JTG__ToolbarBreadcrumbsContainer {
  margin-left: 20px;
  transition: margin .2s ease-in-out, top .2s ease-in-out;
}

.toolbar-module__yM1JTG__ToolbarBreadcrumbsContainer {
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  display: flex;
}

.toolbar-module__yM1JTG__ToolbarBreadcrumb {
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-md);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  text-transform: capitalize;
  margin-right: 5px;
}

.toolbar-module__yM1JTG__ToolbarElement {
  font-family: var(--zbx-font-lato);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  height: -moz-fit-content;
  height: fit-content;
  line-height: 1.6;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: var(--secondary-1) !important;
  margin-right: 1rem !important;
  font-size: 15px !important;
}

.toolbar-module__yM1JTG__ActiveToolbarElement {
  border-bottom: 4px solid var(--primary-1);
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .toolbar-module__yM1JTG__ToolbarBreadcrumbsContainer {
    display: none;
  }

  .toolbar-module__yM1JTG__ToolbarScrollarea {
    max-width: 100%;
  }

  .toolbar-module__yM1JTG__ToolbarContainer {
    grid-template-columns: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .toolbar-module__yM1JTG__ToolbarBreadcrumbsContainer :nth-child(2), .toolbar-module__yM1JTG__ToolbarBreadcrumbsContainer :nth-child(3), .toolbar-module__yM1JTG__ToolbarBreadcrumbsContainer :nth-child(4) {
    display: none;
  }

  .toolbar-module__yM1JTG__ToolbarScrollarea {
    max-width: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .toolbar-module__yM1JTG__ToolbarContainer {
    grid-template-columns: 100%;
    justify-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .toolbar-module__yM1JTG__ToolbarContainer {
    justify-items: flex-start;
  }
}

@media only screen and (min-width: 764px) {
  .toolbar-module__yM1JTG__hideOnDesktop {
    display: none;
  }
}

.SearchInput-module__3j2eYG__searchIconContainer {
  align-items: center;
  height: 100%;
  display: flex;
}

.SearchInput-module__3j2eYG__searchIconButton {
  cursor: pointer;
  padding: var(--zbx-spacing-2);
  background: none;
  border: none;
}

.SearchInput-module__3j2eYG__searchOverlay {
  background: none;
  padding: 0;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
}

.SearchInput-module__3j2eYG__searchWrapper {
  max-width: var(--max-width-default);
  padding: 0 var(--zbx-spacing-4);
  margin: 0 auto;
}

.SearchInput-module__3j2eYG__searchForm {
  width: 100%;
  position: relative;
}

.SearchInput-module__3j2eYG__searchInputWrapper {
  align-items: center;
  width: 94%;
  max-width: 78rem;
  display: flex;
}

.SearchInput-module__3j2eYG__inputContainer {
  border-radius: var(--border-radius-sm);
  border: 1px solid #cdcecd;
  flex: 1;
  position: relative;
}

.SearchInput-module__3j2eYG__inputContainer input {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-light);
  color: var(--primary-2);
  caret-color: var(--primary-1);
  letter-spacing: normal;
  width: 94%;
  line-height: 1.25;
  padding: var(--zbx-spacing-1) 0 !important;
}

.SearchInput-module__3j2eYG__inputContainer button svg {
  display: none;
}

.SearchInput-module__3j2eYG__searchIcon {
  left: var(--zbx-spacing-3);
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.SearchInput-module__3j2eYG__searchIconWrapper {
  align-items: center;
  display: flex;
}

.SearchInput-module__3j2eYG__searchIconWrapper svg {
  fill: var(--primary-2);
}

.SearchInput-module__3j2eYG__actionsWrapper {
  align-items: center;
  gap: var(--zbx-spacing-2);
  height: 60px;
  display: flex;
}

.SearchInput-module__3j2eYG__searchButton {
  height: 100%;
  padding: 0 var(--zbx-spacing-8);
  white-space: nowrap;
  z-index: 2;
  justify-content: center;
  align-items: center;
  height: 4rem;
  font-size: 18px;
  display: flex;
  position: absolute;
  top: -.125rem;
  right: -.125rem;
}

.SearchInput-module__3j2eYG__searchInputContainer a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: none;
}

.SearchInput-module__3j2eYG__closeButton {
  padding: var(--zbx-spacing-2) var(--zbx-spacing-4) var(--zbx-spacing-2) var(--zbx-spacing-6);
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.SearchInput-module__3j2eYG__resultsContainer {
  margin-top: var(--zbx-spacing-1);
  background-color: var(--primary-3);
  border: 1px solid var(--secondary-2);
  border-radius: var(--border-radius-md);
  max-height: 50vh;
  box-shadow: var(--zbx-shadow-1);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.SearchInput-module__3j2eYG__resultItem {
  text-align: left;
  width: 100%;
  padding: var(--zbx-spacing-4);
  cursor: pointer;
  background: none;
  border: none;
}

.SearchInput-module__3j2eYG__resultTitle {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-normal);
  color: var(--primary-2);
  margin-bottom: 2px;
}

.SearchInput-module__3j2eYG__noResults {
  margin-top: var(--zbx-spacing-1);
  padding: var(--zbx-spacing-4);
  background-color: var(--primary-3);
  border: 1px solid var(--secondary-2);
  border-radius: var(--border-radius-md);
  box-shadow: var(--zbx-shadow-1);
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.SearchInput-module__3j2eYG__searchOverlay {
  animation: none;
}

@keyframes SearchInput-module__3j2eYG__slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes SearchInput-module__3j2eYG__slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.SearchInput-module__3j2eYG__SearchInputAutocomplete {
  background-color: #fff;
  border-radius: 4px;
}

.SearchInput-module__3j2eYG__resultTitle {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-light);
  margin-bottom: var(--zbx-spacing-1);
  color: var(--primary-2);
}

.SearchInput-module__3j2eYG__resultSubtitle {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-base);
  color: var(--secondary-1);
}

.SearchInput-module__3j2eYG__unstyledButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font: inherit;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .SearchInput-module__3j2eYG__searchOverlay {
    left: 12rem;
  }

  .SearchInput-module__3j2eYG__resultOption {
    overflow: hidden;
  }

  .SearchInput-module__3j2eYG__resultSubtitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .SearchInput-module__3j2eYG__searchOverlay {
    padding: 1.25rem;
    top: 0;
    left: 0;
  }

  .SearchInput-module__3j2eYG__searchInputWrapper {
    position: relative;
  }

  .SearchInput-module__3j2eYG__actionsWrapper {
    position: absolute;
    right: .75rem;
  }

  .SearchInput-module__3j2eYG__resultOption {
    overflow: hidden;
  }

  .SearchInput-module__3j2eYG__resultSubtitle {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.SearchInput-module__3j2eYG__searchInputContainer {
  align-items: center;
  gap: var(--zbx-spacing-2);
  width: 100%;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .SearchInput-module__3j2eYG__searchInputContainer a {
    display: flex;
  }
}

.BlogGrid-module__J5DaKW__blogGrid {
  flex-direction: column;
  padding: 1rem 1rem .25rem;
  display: flex;
}

.BlogGrid-module__J5DaKW__categories {
  border-bottom: 1px solid var(--secondary-2);
  flex-wrap: wrap;
  margin-bottom: 2rem;
  display: flex;
}

.BlogGrid-module__J5DaKW__categories button {
  white-space: nowrap;
  margin: 0 1.25rem 1rem 0;
  padding: 0 1rem;
}

@media (max-width: 599px) {
  .BlogGrid-module__J5DaKW__categories {
    flex-wrap: nowrap;
    display: flex;
    overflow-x: auto;
  }
}

.BlogGrid-module__J5DaKW__categoriesButton {
  margin-bottom: 1rem;
  margin-right: 1.25rem;
}

.BlogGrid-module__J5DaKW__articlesGrid {
  flex-wrap: wrap;
  flex: 1;
  margin: -1.25rem;
  display: flex;
}

.BlogGrid-module__J5DaKW__articleItem {
  box-sizing: border-box;
  width: 100%;
  min-height: 34.375rem;
  padding: .75rem .75rem 2.5rem;
}

@media (min-width: 768px) {
  .BlogGrid-module__J5DaKW__articleItem {
    width: 50%;
  }
}

.BlogGrid-module__J5DaKW__error {
  color: #bd0000;
  font-size: 1.25rem;
  font-weight: var(--font-weight-bold);
  -webkit-text-decoration: none;
  text-decoration: none;
}

.BlogGrid-module__J5DaKW__loading {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 5rem;
  padding: 2rem;
  display: flex;
}

.BlogGrid-module__J5DaKW__pt4 {
  padding-top: 1rem;
}

.BlogGrid-module__J5DaKW__px4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 768px) {
  .BlogGrid-module__J5DaKW__pxMd1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }
}

.BlogGrid-module__J5DaKW__messageContainer {
  width: 100%;
  padding: 1rem;
}

.BlogGrid-module__J5DaKW__col12 {
  width: 100%;
}

@media (min-width: 768px) {
  .BlogGrid-module__J5DaKW__colMd6 {
    width: 50%;
  }
}

.card-module__SZIJna__cardContainer {
  border-radius: 6px;
  justify-content: flex-end;
  width: 100%;
}

.card-module__SZIJna__singleCard {
  width: 100%;
}

.card-module__SZIJna__cardShadow {
  box-shadow: var(--zbx-shadow-1);
}

.card-module__SZIJna__cardImage {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  height: 260px;
  position: relative;
  overflow: hidden;
}

.card-module__SZIJna__hoverPointer {
  cursor: pointer;
}

.card-module__SZIJna__cardImage > div {
  height: 100%;
}

.card-module__SZIJna__cardImage figure {
  width: 100%;
  height: 100%;
  margin: 0;
}

.card-module__SZIJna__cardImage figure img {
  width: 100%;
  height: 100%;
}

.card-module__SZIJna__cardBody {
  padding: 30px;
}

.card-module__SZIJna__cardTitleContainer {
  height: -moz-fit-content;
  height: fit-content;
  margin: unset;
  align-self: center;
}

.card-module__SZIJna__cardTitle {
  font-family: var(--zbx-font-kievit);
  letter-spacing: normal;
  color: var(--primary-2);
  margin: unset;
  text-align: center;
  font-stretch: normal;
  line-height: normal;
}

.card-module__SZIJna__cardText {
  word-wrap: break-word;
  color: var(--primary-2);
  letter-spacing: normal;
  flex-direction: column;
  align-self: center;
  align-items: center;
  font-family: Lato, Helvetica Neue, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.22;
  display: flex;
}

.card-module__SZIJna__cardText > p a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  text-decoration: none;
}

.card-module__SZIJna__cardText > p a:hover {
  text-decoration: underline;
}

.card-module__SZIJna__cardText p {
  margin: .25rem;
}

.card-module__SZIJna__cardLink {
  align-self: center;
}

.card-module__SZIJna__cardLink:first-letter {
  text-transform: uppercase;
}

.card-module__SZIJna__cardLink:hover {
  color: var(--gold);
}

.card-module__SZIJna__cardCTA {
  align-self: center;
  padding: 10px;
  text-decoration: none;
  display: inline-flex;
}

.card-module__SZIJna__pageTitleElementBtn {
  padding: 0 var(--zbx-spacing-8);
  width: max-content;
}

.card-module__SZIJna__alignStart {
  align-items: flex-start;
  gap: 0;
}

.card-module__SZIJna__alignStart .card-module__SZIJna__cardTitleContainer, .card-module__SZIJna__alignStart .card-module__SZIJna__cardText, .card-module__SZIJna__alignStart .card-module__SZIJna__cardLink, .card-module__SZIJna__alignStart .card-module__SZIJna__cardCTA, .card-module__SZIJna__alignStart .card-module__SZIJna__inlineButtonsContainer .card-module__SZIJna__textImageButton {
  align-self: flex-start;
}

.card-module__SZIJna__inlineButtonsContainer {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.card-module__SZIJna__inlineButtonsContainer .card-module__SZIJna__textImageButton {
  width: 100%;
  max-width: 41.6667%;
  padding: var(--zbx-spacing-3);
  margin: 0 calc(var(--zbx-spacing-3) * -1);
  flex: 0 0 41.6667%;
  display: inline-flex;
}

.card-module__SZIJna__textImageLink {
  margin: var(--zbx-spacing-3) 0;
}

.card-module__SZIJna__infoboxCard {
  padding: var(--zbx-spacing-8) 0 var(--zbx-spacing-4);
}

.card-module__SZIJna__infoboxCard .card-module__SZIJna__cardImage > div {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}

.card-module__SZIJna__infoboxCard .card-module__SZIJna__cardImage {
  justify-content: center;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.card-module__SZIJna__infoboxCard .card-module__SZIJna__cardText p {
  margin: 0 0 var(--zbx-spacing-4) 0;
}

.card-module__SZIJna__infoboxCard .card-module__SZIJna__cardContainer {
  gap: 0;
}

.card-module__SZIJna__infoboxCard .card-module__SZIJna__cardTitleContainer {
  margin-top: var(--margin-sm);
  padding: var(--zbx-spacing-4) var(--zbx-spacing-4) .625rem;
}

.card-module__SZIJna__infoboxCard .card-module__SZIJna__cardImage ~ div, .card-module__SZIJna__contentGridCard .card-module__SZIJna__cardImage ~ div {
  gap: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.card-module__SZIJna__infoboxCard .card-module__SZIJna__cardText {
  padding: 0 var(--zbx-spacing-4) var(--zbx-spacing-4);
}

.card-module__SZIJna__contentGridCard .card-module__SZIJna__cardTitleContainer {
  padding-block: var(--zbx-spacing-4);
}

.card-module__SZIJna__contentGridCard .card-module__SZIJna__cardTitle {
  font-size: var(--font-size-28px);
}

.card-module__SZIJna__contentGridCard .card-module__SZIJna__cardContainer {
  gap: 0;
}

.card-module__SZIJna__contentGridCard .card-module__SZIJna__cardContainer > div {
  gap: 0;
  padding: 0 0 1.125rem;
}

.card-module__SZIJna__contentGridCard .card-module__SZIJna__cardImage {
  aspect-ratio: 5 / 4;
  height: 100%;
}

.card-module__SZIJna__contentGridCard .card-module__SZIJna__cardText {
  padding: 0 0 var(--zbx-spacing-4);
}

.card-module__SZIJna__contentGridCard .card-module__SZIJna__cardText > p {
  text-align: center;
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-light);
  margin: 0;
  line-height: 1.22;
}

.card-module__SZIJna__contentGridCard .card-module__SZIJna__cardCTA:not(a) {
  padding: var(--zbx-spacing-2);
}

@media (min-width: 600px) {
  .card-module__SZIJna__inlineButtonsContainer {
    gap: unset;
    flex-direction: row;
  }

  .card-module__SZIJna__inlineButtonsContainer .card-module__SZIJna__cardLink {
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .card-module__SZIJna__alignStart .card-module__SZIJna__inlineButtonsContainer .card-module__SZIJna__textImageButton {
    align-self: flex-start;
  }

  .card-module__SZIJna__textImageLink {
    margin: 0;
  }

  .card-module__SZIJna__textImageLink a {
    align-items: center;
    display: flex;
  }
}

.card-module__SZIJna__tabsCardInfo {
  gap: 0;
  padding: 0;
}

.card-module__SZIJna__tabsCardImage {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 252px;
}

.card-module__SZIJna__tabsCardTitle {
  align-self: flex-start;
  padding: 16px 16px 0;
}

.card-module__SZIJna__tabsCardTitle > * {
  font-size: var(--font-size-3xl);
}

.card-module__SZIJna__tabsCardText {
  align-self: flex-start;
  padding: 0 16px;
}

.card-module__SZIJna__tabsCardText p {
  margin: 0 0 16px;
}

.card-module__SZIJna__tabsCardLink {
  align-self: flex-start;
  height: 20px;
  margin-bottom: 18px;
  padding: 0 16px 16px;
}

.card-module__SZIJna__tabsCTA {
  width: 100%;
}

.card-module__SZIJna__tabsCTA a {
  height: 30px;
}

.card-module__SZIJna__tabsCTA .card-module__SZIJna__cardCTA {
  width: 100%;
}

.card-module__SZIJna__clickableCard {
  cursor: pointer;
}

@media (max-width: 600px) {
  .card-module__SZIJna__tabsCardImage {
    height: 208px;
  }
}

.card-module__SZIJna__carouselCardInfo {
  gap: 0;
}

.card-module__SZIJna__carouselCardTitle {
  padding: 16px 16px 0;
  font-size: 28px;
}

.card-module__SZIJna__carouselCardText {
  text-align: center;
  margin-bottom: 18px;
}

.card-module__SZIJna__carouselCardLink {
  text-align: center;
  width: 80%;
  margin-bottom: 18px;
  padding: 8px 8px 16px 16px;
}

.card-module__SZIJna__carouselCTA {
  justify-content: center;
  width: 100%;
}

.card-module__SZIJna__carouselCTA .card-module__SZIJna__cardCTA {
  width: 65%;
  height: 32px;
  padding: var(--zbx-spacing-3-extra);
}

@media (min-width: 960px) {
  .card-module__SZIJna__infoboxCard {
    padding: 0 0 var(--zbx-spacing-4);
  }

  .card-module__SZIJna__infoboxCard .card-module__SZIJna__cardText {
    padding: 0 var(--zbx-spacing-12);
  }

  .card-module__SZIJna__contentGridCard .card-module__SZIJna__cardImage {
    border-radius: 0;
  }

  .card-module__SZIJna__contentGridCard .card-module__SZIJna__cardTitle {
    color: var(--primary-2);
    font-family: var(--zbx-font-kievit);
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
  }

  .card-module__SZIJna__contentGridCard .card-module__SZIJna__cardLink {
    color: var(--gold);
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .card-module__SZIJna__singleCard {
    width: 30%;
  }
}

.carrental-module__QGOmtW__zbCarRental {
  margin-bottom: 5rem;
}

.carrental-module__QGOmtW__container {
  max-width: 73%;
  margin: 0 auto;
  padding: 0 .625rem;
}

@media (min-width: 600px) {
  .carrental-module__QGOmtW__container {
    padding: 0 5.5rem;
  }
}

.carrental-module__QGOmtW__headerRow {
  margin-bottom: 2rem;
}

.carrental-module__QGOmtW__headerRow h2 {
  margin: 0;
}

.carrental-module__QGOmtW__headerCenter {
  text-align: center;
  justify-content: center;
  padding: .75rem;
  display: flex;
}

.carrental-module__QGOmtW__contentRow {
  grid-template-columns: 1fr;
  height: 100%;
  display: grid;
}

.carrental-module__QGOmtW__contentRow h3 {
  margin: 0 0 1.5rem;
}

@media (min-width: 960px) {
  .carrental-module__QGOmtW__contentRow {
    grid-template-columns: 1fr 1fr;
  }
}

.carrental-module__QGOmtW__firstColumn, .carrental-module__QGOmtW__secondColumn {
  padding: .75rem;
  overflow: auto;
}

.carrental-module__QGOmtW__firstColumn ul {
  padding: 0;
}

@media (min-width: 960px) {
  .carrental-module__QGOmtW__firstColumn {
    padding-right: 4rem;
  }
}

.carrental-module__QGOmtW__columnHeader {
  margin-bottom: 1.5rem;
}

.carrental-module__QGOmtW__listItem {
  align-items: flex-start;
  min-height: 3.75rem;
  display: flex;
}

.carrental-module__QGOmtW__numberCircle {
  background-color: var(--secondary-3);
  border-radius: var(--border-radius-xl);
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  justify-content: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  margin-right: 1.5rem;
  display: flex;
}

.carrental-module__QGOmtW__itemText {
  color: var(--primary-2);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-sm);
}

.carrental-module__QGOmtW__itemText p {
  margin: 0 0 1rem;
}

.carrental-module__QGOmtW__itemText a {
  color: #d6ab1a;
  text-decoration: none;
}

.carrental-module__QGOmtW__itemText a:hover {
  text-decoration: underline;
}

.carrental-module__QGOmtW__tableData {
  color: var(--primary-2);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-light);
  padding: var(--zbx-spacing-4);
  vertical-align: middle;
  white-space: nowrap;
  height: 35px;
}

.carrental-module__QGOmtW__bold {
  font-weight: var(--font-weight-bold);
}

.carrental-module__QGOmtW__highlightedPrice {
  background-color: var(--primary-1);
  border-radius: var(--border-radius-lg);
  font-size: var(--font-size-lg);
  padding: var(--zbx-spacing-2) var(--zbx-spacing-4);
  white-space: nowrap;
  font-weight: var(--font-weight-bold);
}

@media (max-width: 600px) {
  .carrental-module__QGOmtW__zbCarRental {
    margin-bottom: 1rem;
  }

  .carrental-module__QGOmtW__container {
    max-width: 80%;
  }

  .carrental-module__QGOmtW__contentRow {
    gap: 0;
  }

  .carrental-module__QGOmtW__firstColumn, .carrental-module__QGOmtW__secondColumn {
    padding: 0;
  }
}

@media (min-width: 1900px) {
  .carrental-module__QGOmtW__container {
    max-width: 85%;
  }
}

